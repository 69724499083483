:not(pre) > code,
pre {
    font-size: .875em;
}

:not(pre) > code {
    background-color: $wet-sand;
    color: $storm-cloud;
    padding: 1px 4px;
    border-radius: 3px;
}

pre {
    margin: 1.5em 0;
    -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2;

    * {
        margin: 0;
    }

    code {
        overflow-wrap: break-word;
    }
}