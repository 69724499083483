/* Site Navigation */
nav {
    display: flex;
    flex-wrap: wrap;
    font-size: .9375em;
    
    header &,
    ul {
        justify-content: flex-end;
        align-items: baseline;
    }
// consider re-writing +holy albatross style+
    header & {
        padding: 0 10px;
        @media #{$break-small} {
            font-size: 1rem;
            justify-content: space-between;
        }
    }

    em {
        font-style: normal;
    }

    a {
        display: inline-block;
        padding: 0 .5em;
    }

    ul {
        @include horizontal-list;
    }
}

[data-current="current"] {
    text-transform: capitalize;
    font-variant: small-caps;
}