* {
    margin: 0;
    padding: 0;
}

*, *:before, *:after {
    box-sizing: border-box;
}

* + *:not(li) {
    margin-top: 1em;
}

html,
body {
    margin-top: 0;
}

html {
    line-height: 1.5em;
}

body {
    color: $silver;
    background: $storm-cloud;
}

img {
    max-width: 100%;
}

ul {
    padding-left: 40px;
}

p {
    word-spacing: .05em;
}

[hidden] {
    display: none !important;
}