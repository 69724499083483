footer {
    padding-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;

    small {
        text-align: center;
    }

    a {
        font-size: .833rem;
        padding: 0 1em;
    }

    ul {
        @include horizontal-list;
        justify-content: center;
    }

    li {
        display: inline-block;

        &:before {
            margin-left: -.3125rem;
        }
    }

    li + li {
        a {
            border-left: solid 1px $wet-sand;
        }
    }

    .p-name {
        margin-right: 1em;
    }
}