@mixin grid-base {
    display: grid;
    
    /* Fix bug with grid, where content breaks out of container */
    min-width: 0;
    min-height: 0;
    
    > * {
        min-width: 0;
        overflow: hidden;
    }
    /* End grid bug fix */
}

@mixin horizontal-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    
    li {
        list-style-type: none;
        margin-left: -.25rem;
        
        &:before {
            /* https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/ */
            /* add zero-width space trigging VoiceOver to announce the list items #accessibility */
            content: "\200B";
        }
    }
}

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}