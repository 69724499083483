/* Contact Form  -- Currently not in use */
form {

}

input,
textarea,
button {
    padding: 0.625rem;
    font-size: 1rem;
    font-family: "Montserrat", Verdana, Helvetica, Arial, sans-serif;
}

input,
textarea {
    border: 1px solid black;
    margin-top: 0;
    margin-bottom: 1em;
    width: 100%;
}

button {
    border: 1px solid $wet-sand;
    background-color: $wet-sand;
    color: $ruby;
    cursor: pointer;
}

.fields {
    display: grid;
    padding: 0 0.625rem;

    li {
        list-style-type: none;
    }
}

.field__buttons {
    display: flex;
    justify-content: center;
}