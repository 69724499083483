/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i');

/* Colors */
$storm-cloud: #4e4e4e;
$silver:  #eef6ff;
$ruby: #90253c;
$wet-sand: #bfbfbf;

/* Breakpoints */

// 401px
$break-small: "screen and (min-width: 25.0625rem)";

// 450px
$break-medium: "screen and (min-width: 28.125rem)";

// 600px
$break-large: "screen and (min-width: 37.5rem)";

// 960px
$break-xlarge: "screen and (min-width: 60rem)";