@import "bedrock/_variables";
@import "bedrock/_mixins";
@import "bedrock/_global";
@import "bedrock/_type";
@import "templates/_base";
@import "templates/_landing";
@import "templates/_article";
@import "modules/_header";
@import "modules/_figure";
@import "modules/_footer";
@import "modules/_form";
@import "modules/_meta";
@import "syntax/_highlight";
@import "syntax/_custom";

/* REMINDER:  Use more n + n selectors. reduce bloat
created by undoing something with :first/last-child */

/*
================
TODO
================

Handle direct-link adding large space after
Ex: heading http://localhost:8080/posts/new-approach-new-site/
*/