figure {
    margin-bottom: 2em;
    text-align: center;

    img {
        display: block;
    }
}

figcaption {
    font-size: smaller;
    font-style: italic;
    margin-top: .5rem;
}