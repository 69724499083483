html {
    font-family: "Montserrat", Verdana, Helvetica, Arial, sans-serif;
    font-display: optional;
}

body {
    font-size: 1rem;

    // can this be rewritten w/o media query?
    @media #{$break-xlarge} {
        font-size: 1.125rem;
    }
}

// Links
a {

    &,
    &:visited {
        color: $wet-sand;
    }

    &:hover,
    &:active,
    &:focus {
        color: $silver;
    }
}

sup {
    font-size: .75rem;
}

.direct-link {
    padding: 0 1.25rem;

    &,
    &:visited {
        color: transparent;
    }

    :hover>&,
    :focus>& {
        color: $wet-sand;
    }

    &:hover,
    &:active,
    &:focus {
        color: $silver;
    }
}

/* Post Tags */
[rel="tag"] {
    display: inline-block;
    font-size: .625rem;
    letter-spacing: .1em;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: .5em;
    padding: 0 .5em;
    line-height: 2em;
    background-color: $ruby;
    border: 1px solid rgba($wet-sand, 0.25);
    border-radius: .25em;

    &+& {
        margin-left: .5em;
    }

    &,
    &:visited {
        color: $wet-sand;
    }

    &:hover {
        border: 1px solid $wet-sand;
        background-color: $wet-sand;
        color: $ruby;
    }
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
    font-variant: small-caps;
    letter-spacing: 0.025em;
}

h2,
h2:first-child,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin-top: 1.5rem;
}

h1 {
    font-size: 2rem;
    font-weight: bold;
}

h2 {
    font-size: 1.5rem;
    display: flex;
}

blockquote {
    border-left: ridge 15px rgba($silver, .5);
    border-top: ridge 7.5px rgba($silver, .5);
    background-color: rgba($wet-sand, .3);
    padding: 1em;
}