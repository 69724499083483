/* Base Layout */

// Page Grid
body {
    @include grid-base;
    grid-template-rows: auto minmax(auto, 1fr) auto;
    grid-template-columns: 1fr minmax(auto, 60ch) 1fr;
    grid-column-gap: 1em;
    grid-template-areas:
        "header header header"
        "...... main ......"
        "footer footer footer";
    min-height: 100vh;
}

header {
    grid-area: header;
    margin-top: 0;
    padding: 1em 0;
}

main {
    grid-area: main;
    margin-top: 2em;
}

footer {
    grid-area: footer;
}

// Other Styles
footer,
section,
aside {
    margin-top: 1.5em;
}

article {
    border-bottom: 1px solid $wet-sand;
    padding-bottom: 1.5rem;

    ol,
    ul {
        padding-left: 20px;

        @media #{$break-xlarge} {
            padding-left: 40px;
        }
    }
}